import SectionInfo from '../sections/section-info/section-info';

function InfoPage() {
    return (
        <>
            <SectionInfo />
        </>
    );
}

export default InfoPage;